import React, { FC } from 'react'
import { Box, Text } from 'grommet'
import { Currency } from 'components/Number'
import { VehicleSaleQueryResult, CurrentSaleQueryResult } from 'contexts/ApiContext/services/sale'
import { useAuth } from 'contexts/AuthContext'
import styles from './Offer.module.scss'
import { useIntl } from 'react-intl'
import HandlingFees from '../../../components/HandlingFees'
import { calculateVatFreePrice } from 'services/helpers'

const Offer: FC<{
  vehicle: VehicleSaleQueryResult
  currentSale: CurrentSaleQueryResult
  isWaiting: boolean
}> = ({ vehicle, currentSale, isWaiting }) => {
  const { user } = useAuth()
  const { formatMessage } = useIntl()
  const lastAuction = vehicle.lastAuctions?.[0]
  const currentPrice = lastAuction ? lastAuction.amount + vehicle.pricePitch : vehicle.startingPrice

  return (
    <Box align="center" margin={{ vertical: 'small' }}>
      <Text size="huge" color="gray-900">
        {formatMessage({ id: 'client.auction.starting_price' })}{' '}
        <Currency className={styles.price} value={vehicle.startingPrice} size="huge" />
      </Text>
      {isWaiting && vehicle.vat && (vehicle.category === 'Utilitaire' || user.isForeigner) && (
        <Text margin={{ top: 'small' }} weight="bold">
          {formatMessage({ id: 'client.auction.vat_excluded' })} :{' '}
          {calculateVatFreePrice(currentPrice, vehicle.vatAmount)} €
        </Text>
      )}
      <Text size="medium" margin={{ top: 'small' }}>
        {formatMessage({ id: 'client.auction.fees_included' })}
        <sup>1</sup>
      </Text>
      <Text size="medium">
        <HandlingFees currentSale={currentSale} />
      </Text>
    </Box>
  )
}

export default Offer
