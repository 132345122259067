import Bids from 'app/components/Bids'
import TimerC from 'app/components/Timer'
import Spinner from 'components/Spinner'
import {
  AuctionQueryResult,
  CurrentSaleQueryResult,
  VehicleSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import { useAuth } from 'contexts/AuthContext'
import { Box, Button, Text } from 'grommet'
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Offer from '../Offer/component'
import styles from './Binding.module.scss'
import { Error } from './container'
import HandlingFees from '../../../components/HandlingFees'
import { calculateVatFreePrice } from 'services/helpers'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
  currentSale: CurrentSaleQueryResult
  biding: boolean
  hasBidded: boolean
  error: Error
  sendBid: (amount: number) => void
  tick: number | null
}

const Biding: FC<OwnProps> = ({
  vehicle,
  currentSale,
  auctions,
  sendBid,
  hasBidded,
  error,
  tick,
}) => {
  const { formatMessage } = useIntl()
  const { user } = useAuth()
  const [areBidsDisplayed, setAreBidsDisplayed] = useState(false)

  useEffect(() => {
    if (!vehicle.lotOpen) {
      setAreBidsDisplayed(false)
    }
  }, [vehicle.lotOpen])

  const [biddedAlready /*setBiddedAlready*/] = useState(false)

  const increment = currentSale.bidIncrement || vehicle.pricePitch

  const lastAuction = vehicle.lastAuctions?.[0]
  const lastAuctionUserName = auctions[0]?.user?.name
  const currentPrice =
    vehicle.highestAuction.type === 'Départ'
      ? Number(vehicle.highestAuction.amount)
      : lastAuction
      ? Number(lastAuction.amount) + increment
      : Number(vehicle.highestAuction.amount) + increment

  return (
    <>
      <Box background="gray-200" justify="center" pad="medium" align="center">
        <Box direction="column" justify="center" align="center">
          <TimerC tick={tick} />
          {(!vehicle.lotOpen || currentSale.openedVehicle === null) && (
            <Text
              className={styles.uppercase}
              color="red-600"
              size="huge"
              weight="bold"
              style={{ marginTop: 10 }}
            >
              {formatMessage({ id: 'client.auction.lot_closed' })}
            </Text>
          )}
        </Box>
        {vehicle.lotOpen && !user.isDemoUser && areBidsDisplayed && (
          <>
            <Box align="center" justify="center" margin={{ top: 'medium' }}>
              {/* Winning */}

              {vehicle.auctionOpen &&
                auctions.length > 0 &&
                lastAuctionUserName === user.name &&
                tick !== 0 && (
                  <Text weight="bold" size="large" textAlign="center" color="lime-600">
                    {formatMessage({ id: 'client.auction.winning' })}
                  </Text>
                )}
              {/* Losing */}
              {hasBidded &&
                vehicle.auctionOpen &&
                auctions.length > 0 &&
                lastAuctionUserName !== user.name &&
                !error.isError &&
                vehicle.auctionOpen && (
                  <Text weight="bold" size="large" textAlign="center" color="red-600">
                    {formatMessage({ id: 'client.auction.losing' })}
                  </Text>
                )}

              {/* Same amount */}
              {((biddedAlready && tick) || 0 > 5) && (
                <Text weight="bold" size="large" textAlign="center" color="black">
                  {formatMessage({ id: 'client.auction.same_amount' })}
                </Text>
              )}
              {vehicle.auctionOpen && error.isError && <Text>{error.message}</Text>}
            </Box>
            <>
              {vehicle.auctionOpen && !vehicle.pausedAt && tick && areBidsDisplayed && (
                <Box margin={{ top: 'medium' }} align="center" width="100%">
                  {(auctions.length === 0 || lastAuctionUserName !== user.name) && (
                    <Button
                      data-cy="button-bid-customer"
                      primary
                      color="lime-500"
                      className={styles.button}
                      onClick={() => sendBid(currentPrice)}
                      label={
                        <Text
                          className={styles.uppercase}
                          weight="bold"
                          size="xxlarge"
                          color="white"
                        >
                          {formatMessage({ id: 'client.auction.bid' })}
                          <br /> {currentPrice} €
                        </Text>
                      }
                    ></Button>
                  )}
                  {auctions.length > 0 && lastAuctionUserName === user.name && (
                    <Button
                      primary
                      disabled={true}
                      className={styles.button}
                      label={
                        <Text className={styles.uppercase} weight="bold" size="xxlarge">
                          {formatMessage({ id: 'client.auction.bid' })}
                          <br /> {currentPrice} €
                        </Text>
                      }
                    ></Button>
                  )}
                  {vehicle.vat === true && (vehicle.category === 'Utilitaire' || user.isForeigner) && (
                    <Text margin={{ top: 'small' }} weight="bold">
                      {formatMessage({ id: 'client.auction.vat_excluded' })} :{' '}
                      {calculateVatFreePrice(currentPrice, vehicle.vatAmount)} €
                    </Text>
                  )}
                </Box>
              )}
            </>
          </>
        )}
        {vehicle.lotOpen && user.isDemoUser && (
          <Button
            margin={{ top: '20px' }}
            gap="none"
            primary
            color="red-600"
            href={`${process.env.REACT_APP_API_URI}/live-redirect`}
            target="_blank"
            label={
              <Text size="large" weight="bold">
                {formatMessage({ id: 'client.auction.register_to_bid' })}
              </Text>
            }
          ></Button>
        )}
        {vehicle.auctionOpen && (
          <>
            {user.isDemoUser && vehicle.auctionOpen && (
              <>
                <Text weight="bold" size="40px">
                  {formatMessage({ id: 'client.auction.next_auction' })}
                </Text>
                <Box
                  border={{ color: 'red', size: 'medium' }}
                  pad="xsmall"
                  round="xsmall"
                  margin={{ vertical: 'small' }}
                >
                  <Text size="50px">{currentPrice} €</Text>
                </Box>
              </>
            )}
            {tick && (
              <>
                <Text size="small" margin={{ top: 'small' }}>
                  {formatMessage({ id: 'client.auction.fees_included' })}
                  <sup>1</sup>
                </Text>
                <Text size="small">
                  <HandlingFees currentSale={currentSale} />
                </Text>{' '}
              </>
            )}
          </>
        )}
      </Box>

      {vehicle.lotOpen && currentSale.openedVehicle && (
        <>
          {!lastAuction && vehicle.auctionOpen && auctions.length === 0 && tick && (
            <>
              <Spinner />
              <Offer vehicle={vehicle} currentSale={currentSale} isWaiting={false} />
            </>
          )}

          <Box align="center" margin={{ vertical: 'small' }} justify="end">
            <Bids auctions={auctions} onIntervalEnds={() => setAreBidsDisplayed(true)} />
          </Box>
        </>
      )}

      {hasBidded && !user?.isDemoUser && vehicle?.openedAt && !currentSale?.openedVehicle && (
        <>
          {vehicle?.salingState === 'Adjugé' && lastAuction?.user?.name === user?.name && (
            <Box align="center" margin={{ top: 'large' }}>
              <Text
                size="xxlarge"
                color="lime-600"
                weight="bold"
                className={styles.uppercase}
                data-cy="client-auction-won"
                textAlign="center"
              >
                {formatMessage({ id: 'client.auction.won_text1' })}
              </Text>
              <Text textAlign="center" size="large">
                {formatMessage({ id: 'client.auction.won_text2' })}
              </Text>
            </Box>
          )}
          {lastAuction &&
            (vehicle?.salingState !== 'Adjugé' || lastAuction?.user?.name !== user.name) && (
              <Box align="center" margin={{ vertical: 'large' }}>
                <Text
                  size="xxlarge"
                  textAlign="center"
                  color="red-600"
                  weight="bold"
                  className={styles.uppercase}
                >
                  {formatMessage({ id: 'client.auction.lost_text' })}
                </Text>
              </Box>
            )}
        </>
      )}
    </>
  )
}

export default Biding
