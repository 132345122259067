import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, BoxProps, Text } from 'grommet'
import Pricing from './Pricing'
import { useIntl } from 'react-intl'
import {
  VehicleSaleQueryResult,
  AuctionQueryResult,
  CurrentSaleQueryResult,
} from 'contexts/ApiContext/services/sale'
import Waiting from './Waiting'
import Biding from './Biding'
import Timer from 'services/timer'
import HandlingFees from '../../components/HandlingFees'

interface OwnProps {
  vehicle: VehicleSaleQueryResult
  auctions: AuctionQueryResult[]
  currentSale: CurrentSaleQueryResult
}

const Auctions: FC<OwnProps & BoxProps> = ({ currentSale, vehicle, auctions, ...props }) => {
  const { formatMessage } = useIntl()

  const [tick, setTick] = useState<number | null>(null)
  const isNotFirstUpdate = useRef(false)
  const prevLot = useRef<number | null>(vehicle.lot)
  const actualLot = useRef(vehicle.lot)

  useEffect(() => {
    Timer.getInstance().on('tick', (ms: number) => {
      const timeLeft = Math.ceil(ms / 1000)
      setTick(timeLeft < 0 ? null : timeLeft)
      if (actualLot.current !== prevLot.current) {
        setTick(null)
      }
    })
    isNotFirstUpdate.current = true
  }, [])

  // when open lot
  useEffect(() => {
    actualLot.current = vehicle.lot
    prevLot.current = null
    setTick(null)
  }, [vehicle.lot, vehicle.lotOpen])

  // when open auctions
  useEffect(() => {
    prevLot.current = vehicle.lot
  }, [vehicle.updatedAt])

  const isPausedSale =
    (!auctions || auctions.length === 0) && currentSale.openedVehicle && !tick && tick !== 0
  const isWaitingToOpen =
    !vehicle.lotOpen && vehicle.salingState !== 'Adjugé' && vehicle.salingState !== 'Retiré'

  return (
    <Box {...props}>
      {isPausedSale || isWaitingToOpen ? (
        <Waiting vehicle={vehicle} currentSale={currentSale} />
      ) : (
        <Biding currentSale={currentSale} vehicle={vehicle} auctions={auctions} tick={tick} />
      )}
      <Box direction="column" justify="center" align="center">
        {vehicle.salingState === 'Adjugé' && !vehicle.lotOpen && (
          <Box style={{ marginBottom: 40, marginTop: 40 }} align="center">
            <Text weight="bold" size="xlarge" style={{ textTransform: 'uppercase' }}>
              {formatMessage({ id: 'client.auction.sold' })} :&nbsp;
              {vehicle.highestAuction.amount && Math.round(Number(vehicle.highestAuction.amount))}€
            </Text>
            <Text size="small">
              {formatMessage({ id: 'client.auction.fees_included' })}
              <sup>1</sup>
            </Text>
            <Text size="small">
              <HandlingFees currentSale={currentSale} />
            </Text>
          </Box>
        )}

        <Box direction="row" justify="center">
          <Pricing
            label={formatMessage({ id: 'client.vehicle.quote' })}
            value={parseInt(vehicle.argusDatedPrice.replace(/ /g, ''))}
            sup={2}
          />
          <Pricing
            label={formatMessage({ id: 'client.vehicle.new_price' })}
            value={parseInt(vehicle.unusedPrice.replace(/ /g, ''))}
            sup={3}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Auctions
